import React, { FunctionComponent, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    useFilterResults,
    Row,
    TripoCard,
    Col,
    Button,
    Divider,
    Price,
} from "@triporate/triporate-design-system";
import { AppliedFilters } from "../../../commonTypes";
import { parseLocationSearch, validateTextTypeString } from "../../../../utils";
import LoadingResults from "../LoadingResults";
import NoResultsMessage from "../NoResultsMessage";
import { SegmentId, RequestData, ResultsData } from "./types";
import Context from "./Context";
import Solutions from "./Solutions";
import ConfirmationDrawer from "../ConfirmationDrawer";
import "./TransportResults.scss";
import TravelSummaryComponent from "./TravelSummary/TravelSummaryComponent";
import { EShowMode } from "./Context";
import TripModeSelectorComponent from "./TripModeSelector/TripModeSelectorComponent";
import { getTransportResults } from "../../../../services/Results";
import { IncompatibleScheduleModal } from "./IncompatibleScheduleModal/IncompatibleScheduleModal";

type TransportResultsProps = {
    requestData?: RequestData;
    appliedFilters?: AppliedFilters[];
    appliedOrder?: AppliedFilters;
    setPaymentsRequestData: (url: string, body: string) => void;
    setIsAfterSearch?: (isAfterSearch: boolean) => void;
};

const TransportResultsV3: FunctionComponent<TransportResultsProps> = ({
    requestData,
    appliedFilters = [],
    setPaymentsRequestData,
    appliedOrder,
    setIsAfterSearch,
}) => {
    const { search } = useLocation();
    const [searched, setSearched] = useState(false);
    const [results, setResults] = useState<ResultsData["results"]>([]);
    const [noResults, setNoResults] = useState<ResultsData["noResults"]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [cleanAncillaries, setCleanAncillaries] = useState(false);
    const [ancillariesRequestData, setAncillariesRequestData] =
        useState<RequestData | null>(null);
    const [summaryRequestData, setSummaryRequestData] =
        useState<RequestData | null>(null);

    const [openedSolutions, setOpenedSolutions] = useState<string[]>([]);

    const [returnResults, setReturnResults] = useState<ResultsData["results"]>(
        []
    );

    const [filteredResults, setAppliedFilters, setAppliedOrder] =
        useFilterResults(results);

    // New code
    const [outTripSelected, setOutTripSelected] = useState<any>();

    const [returnTripSelected, setReturnTripSelected] = useState<any>();

    const [tripModeSeleted, setTripModeSeleted] = useState<EShowMode>(
        EShowMode.FROM
    );

    const [seletedProvider, setSeletedProvider] = useState<string>();

    const [returnResultsByProvider, setReturnResultsByProvider] = useState<
        ResultsData["results"]
    >([]);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [isErrorPricing, setIsErrorPricing] = useState<boolean>(false);

    const [isOnRefresh, setIsOnRefresh] = useState<boolean>(false);

    React.useEffect(() => {
        () => {
            return resetSummary();
        };
    }, []);

    useEffect(() => {
        const resultFilter = returnResults.filter(
            (item: any) => item.provider.name == seletedProvider
        );
        setReturnResultsByProvider(resultFilter);
    }, [seletedProvider, tripModeSeleted]);

    useEffect(() => {
        const handleFetch = async ({ url, body }: any) => {
            setResults([]);
            setSearched(true);
            setLoading(true);
            const { data } = await getTransportResults(url, body);

            if (data) {
                resetSummary();
                setResults(data.results);
                setReturnResults(data.returnResults);
                setNoResults(data.noResults);
                setIsAfterSearch ? setIsAfterSearch(true) : "";
            }
            setLoading(false);
        };

        if (requestData) handleFetch(requestData);
    }, [requestData]);

    useEffect(() => {
        setAppliedFilters(appliedFilters);
    }, [appliedFilters, setAppliedFilters]);

    useEffect(() => {
        setAppliedOrder(appliedOrder);
    }, [appliedOrder, setAppliedOrder]);

    useEffect(() => {
        const search: any = results.find(
            (item: any) =>
                item.journeys[0][0].optionId == outTripSelected.optionId
        );
        setSeletedProvider(search?.provider?.name);
    }, [outTripSelected]);

    const onTripSelectedEvent = (tripSeleted: any) => {
        setOpenedSolutions([]);

        if (tripModeSeleted == EShowMode.FROM) {
            setOutTripSelected(tripSeleted);
            setTripModeSeleted(EShowMode.TO);
        } else {
            setReturnTripSelected(tripSeleted);
        }
    };

    const onBuyButtonClick = (
        url: string,
        ids: {
            [key: number]: SegmentId;
        },
        segmentId: string,
        code?: string
    ) => {
        const offerId = parseLocationSearch(search, "offerId");
        const tripId = parseLocationSearch(search, "tripId");
        const body = JSON.stringify({
            offerId,
            journeys: Object.values(ids),
            tripId,
            residentsCode: code,
        });

        setAncillariesRequestData({ url, body });
    };

    const onRefreshPricingButtonClick = () => {
        setIsOnRefresh(true);
    };

    const resetSummary = () => {
        setOutTripSelected(null);
        setReturnTripSelected(null);
        setOpenedSolutions([]);
        setTripModeSeleted(EShowMode.FROM);
        setSeletedProvider("");
        setReturnResultsByProvider([]);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    if (loading) return <LoadingResults />;

    if (!searched) return <></>;

    if (searched && !results?.length)
        return <NoResultsMessage message={noResults?.apiMsg} />;

    if (searched && !filteredResults?.length)
        return <NoResultsMessage message={noResults?.filtersMsg} />;

    if (
        searched &&
        tripModeSeleted === EShowMode.TO &&
        !returnResultsByProvider?.length
    )
        return <NoResultsMessage message={noResults?.apiMsg} />;

    return (
        <Context.Provider
            value={{
                onBuyButtonClick,
                openedSolutions,
                setOpenedSolutions,
                onTripSelectedEvent,
                ancillariesRequestData,
                onOpenModal,
                isErrorPricing,
                setIsErrorPricing,
                onRefreshPricingButtonClick,
            }}
        >
            <TravelSummaryComponent
                solutions={filteredResults}
                outTrip={outTripSelected}
                returnTrip={returnTripSelected}
                setSummaryRequestData={setSummaryRequestData}
                searchFormRequestData={requestData}
            />

            <Row>
                <Col
                    className="triop-mode-selector"
                    span={24}
                    style={{ zIndex: 1 }}
                >
                    <TripModeSelectorComponent
                        tripModeSelected={tripModeSeleted}
                        onChangeModeEvent={setTripModeSeleted}
                    />
                </Col>

                <Col span={24}>
                    {tripModeSeleted == EShowMode.FROM ? (
                        <Solutions solutions={filteredResults} />
                    ) : (
                        <Solutions solutions={returnResultsByProvider} />
                    )}
                </Col>
            </Row>

            <ConfirmationDrawer
                requestData={summaryRequestData}
                cleanRequestData={() => {
                    setSummaryRequestData(null);
                    setCleanAncillaries(true);
                }}
                setPaymentsRequestData={setPaymentsRequestData}
                isOnRefresh={isOnRefresh}
                setIsOnRefresh={setIsOnRefresh}
                cleanOnClose
            />

            <IncompatibleScheduleModal
                isModalOpen={isModalOpen}
                onClickButton={() => setIsModalOpen(false)}
            />
        </Context.Provider>
    );
};

export default TransportResultsV3;
