import React, { FunctionComponent } from "react";
import { HotelInputs, TransportInputs, CarInputs } from "./InputGroups";
import { InputData, FormValues, Fields, SubmitData } from "../../types";
import { ValidInputGroups } from "../constants";
import { ValidTypes } from "../../../validTypes";
import { WarningInformation } from "../../../../pages/SelfBookingTool/types";

type InputGroupSwitchProps = {
    isABT: boolean;
    type?: string;
    inputs: { [key: string]: InputData };
    addNewTraveller?: SubmitData;
    showTravellerFormIFrame?: () => void;
    newTravellerIdCreated?: string;
    formValues: FormValues;
    isFirstSubmit: boolean;
    getFieldValue: (inputName: string) => unknown;
    setFieldsValue: (values: Fields) => void;
    setFieldsAmount?: (amount: number) => void;
    isEstimate?: boolean;
    estimateLabel?: string;
    validateFields: (value: string[]) => void;
    label: string;
    onSubmit: () => void;
    warningInfo?: WarningInformation;
    isAfterSearch?: boolean;
};

const InputGroupSwitch: FunctionComponent<InputGroupSwitchProps> = ({
    type,
    setFieldsAmount,
    ...props
}): JSX.Element => {
    switch (type) {
        case ValidInputGroups.hotel:
            return <HotelInputs {...props} />;
        case ValidInputGroups.car:
            return <CarInputs {...props} />;
        case ValidTypes.hotel:
            return <HotelInputs {...props} />;
        case ValidTypes.flight:
            return (
                <TransportInputs {...props} setFieldsAmount={setFieldsAmount} />
            );
        case ValidTypes.train:
            return <TransportInputs {...props} isTrain />;
        default:
            return <></>;
    }
};

export default InputGroupSwitch;
